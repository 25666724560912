/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";

/*Amplif*/
@import '../node_modules/@aws-amplify/ui-angular/theme.css';

// import Quill styles in styles.scss
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

html, body {
    height: 100%;
}

.ql-container {
    min-height: calc(100vh - ( 5rem + 2rem + 1rem + 3rem + 5rem + 1rem) );
    max-height: calc(100vh - ( 5rem + 2rem + 1rem + 3rem + 5rem + 1rem) );
    height: calc(100vh - ( 5rem + 2rem + 1rem + 3rem + 5rem + 1rem) );
    //flex: 1;
    //display: flex;
    //flex-direction: column;
    background-color: #ffffff;
    border-bottom-color: rgb(226, 232, 240);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(226, 232, 240);
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: rgb(226, 232, 240);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    //min-width: 100;
    //width: 100%;
    //flex-grow: 100;
}
.ql-toolbar {
    background-color: #ffffff;
    border-left-color: rgb(226, 232, 240);
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: rgb(226, 232, 240);
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: rgb(226, 232, 240);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-top-style: solid;
    border-top-width: 1px;
    width: 100%;
    flex-grow: 100;
}
.ql-editor {
    height: 100%;
    background-color: #ffffff;
    //flex: 1;
    overflow-y: auto;
    //width: 100%;
    //flex-grow: 100;
}

body {
    --text-color: #484848;
}

